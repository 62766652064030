import { useEffect, useState, useContext, useCallback } from 'react';
import { useQuery } from 'react-apollo';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useSessionData, AccessStatus } from '@confluence/session-data';
import { CoreInvitesContext, CoreInvitesSource } from '@confluence/core-invites-provider';
import { CONTEXTUAL_INVITE_NUDGE } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import {
	useGetOnboardingState,
	useOnboardingState,
	deserializeState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { memoizedGetInvitePermissions } from '@confluence/onboarding-helpers/entry-points/getInvitePermissions';
import {
	suppressInfiSpotlight,
	resetInfiSpotlightValue,
} from '@confluence/onboarding-helpers/entry-points/infiSpotlightUtils';
import { isUnauthorizedError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import { fg } from '@confluence/feature-gating';

import type {
	LivePageContentContributorsQuery as LivePageContentContributorsQueryType,
	LivePageContentContributorsQueryVariables,
} from './__types__/ContentContributorsQuery';
import { ContentContributorsQuery } from './ContentContributorsQuery.graphql';

const CONFLUENCE_KEY = 'confluence';

// Onboarding state keys
const LIVE_PAGE_NUDGE = {
	LIVE_PAGE_CONTEXTUAL_NUDGE_SEEN: 'live-page-nudge:wasLivePageNudgeSeen',
	// used to limit calls to the invite permissions API
	LIVE_PAGE_INVITE_INELIGIBLE: 'live-page-nudge:livePageNudgeInviteIneligible',
	HAS_SEEN_FIRST_LIVE_PAGE: 'live-page-nudge:livePageNudgeHasSeenFirstLivePage',
};

interface EligibilityProps {
	contentId?: string;
}

interface LivePageOnboardingNudgeEligible {
	isEligibleForLivePageNudge: boolean;
	isShowingLivePageNudge: boolean;
	showSpotlight: () => void;
	markFirstLivePageAsSeen: () => void;
	isLoading: boolean;
	livePageNudgeHasSeenFirstLivePage: boolean;
}

/**
 * Determines eligibility for the Live Page Nudge experiment
 * @returns {object} - an object containing:
 * @returns {boolean} isEligibleForLivePageNudge- Determines whether the user is eligible for the contextual live page nudge
 * @returns {boolean} isShowingLivePageNudge- Determines whether the nudge is showing to help for any future deconflicts
 * @returns {function} showSpotlight - Opens the Core Invites Modal
 * @returns {function} markFirstLivePageAsSeen - Updates onboarding state if user has seen their first live page
 * @returns {boolean} isLoading - returns whether we are waiting on any queries to finish running
 * @returns {boolean} livePageNudgeHasSeenFirstLivePage - status of whether the user has seen their first live page
 */
export const useLivePageOnboardingNudgeEligible = ({
	contentId,
}: EligibilityProps): LivePageOnboardingNudgeEligible => {
	const [isCurrentlyInviteEligible, setIsCurrentlyInviteEligible] = useState(false);
	const [isShowingLivePageNudge, setIsShowingLivePageNudge] = useState(false);
	const { isLoggedIn, accessStatus, cloudId } = useSessionData();
	const { openCoreInvites } = useContext(CoreInvitesContext);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { setOnboardingState } = useOnboardingState();

	const isLicensed =
		accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
		accessStatus === AccessStatus.LICENSED_USE_ACCESS;
	const isLicensedAndLoggedIn = isLoggedIn && isLicensed;

	const isLivePagesABTestEnabled = fg('confluence_live_pages_ab_test_opted_in');

	const {
		data: onboardingStateData,
		loading: onboardingStateLoading,
		error: onboardingStateError,
	} = useGetOnboardingState(
		[...Object.values(CONTEXTUAL_INVITE_NUDGE), ...Object.values(LIVE_PAGE_NUDGE)],
		// skip conditions
		!isLivePagesABTestEnabled,
	);

	// User Eligibility Check
	const onboardingState = deserializeState(onboardingStateData);
	const {
		wasFirstPublishConfettiSeen,
		wasLivePageNudgeSeen,
		livePageNudgeInviteIneligible,
		livePageNudgeHasSeenFirstLivePage,
	} = onboardingState;

	// Optimize by skipping calls to expensive page eligibility queries
	const skip =
		!isLivePagesABTestEnabled ||
		!isLicensedAndLoggedIn ||
		onboardingStateLoading ||
		!contentId ||
		wasLivePageNudgeSeen ||
		livePageNudgeInviteIneligible ||
		// deconflict with non live page contextual nudge
		wasFirstPublishConfettiSeen;

	// Page Eligibility Check
	const {
		data: contentContributorsData,
		loading: contentContributorsLoading,
		error: contentContributorsError,
	} = useQuery<LivePageContentContributorsQueryType, LivePageContentContributorsQueryVariables>(
		ContentContributorsQuery,
		{
			variables: {
				// asserts there will always be a contentId before running
				contentId: contentId!,
			},
			skip,
		},
	);

	const isCurrentUserContributor =
		contentContributorsData?.contentContributors?.isCurrentUserContributor ?? false;

	const showSpotlight = useCallback(() => {
		suppressInfiSpotlight();

		openCoreInvites?.(CoreInvitesSource.LIVE_PAGES_NUDGE, {
			onModalClosed: () => {
				// note: this automatically gets called through onInviteCompleted
				resetInfiSpotlightValue();
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						source: CoreInvitesSource.LIVE_PAGES_NUDGE,
						action: 'closed',
						actionSubject: 'inviteModal',
						actionSubjectId: 'inviteModalCancelButton',
					},
				}).fire();
				setIsShowingLivePageNudge(false);
			},
			onInviteCompleted: () => {
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						source: CoreInvitesSource.LIVE_PAGES_NUDGE,
						action: 'completed',
						actionSubject: 'inviteModal',
						actionSubjectId: 'inviteModalInviteButton',
					},
				}).fire();
			},
			getContextualInviteNudgeProps: () => {
				return { showSpotlight: true };
			},
		});
		setIsShowingLivePageNudge(true);
		void setOnboardingState({
			key: LIVE_PAGE_NUDGE.LIVE_PAGE_CONTEXTUAL_NUDGE_SEEN,
			value: 'true',
		});
	}, [createAnalyticsEvent, openCoreInvites, setOnboardingState]);

	const markFirstLivePageAsSeen = useCallback(() => {
		if (!livePageNudgeHasSeenFirstLivePage) {
			void setOnboardingState({
				key: LIVE_PAGE_NUDGE.HAS_SEEN_FIRST_LIVE_PAGE,
				value: 'true',
			});
		}
	}, [setOnboardingState, livePageNudgeHasSeenFirstLivePage]);

	const hasNoInvitePermissions = !onboardingStateLoading && livePageNudgeInviteIneligible;

	const isLoading = !!(onboardingStateLoading || contentContributorsLoading);
	const error = onboardingStateError || contentContributorsError;

	if (error) {
		if (isUnauthorizedError(error)) {
			markErrorAsHandled(error);
		}
	}

	useEffect(() => {
		if (skip || isLoading || !!error || !isCurrentUserContributor || hasNoInvitePermissions) {
			return;
		}

		const getInvitePermissions = async () => {
			const response = await memoizedGetInvitePermissions(cloudId);
			if (response instanceof Error) {
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'failed',
						actionSubject: 'getInvitePermissions',
						source: 'livePages',
						attributes: {
							message: response?.message,
						},
					},
				}).fire();
			}

			const canInviteToConfluence: boolean = [
				...(response?.directInvite ?? []),
				...(response?.invitePendingApproval ?? []),
			].some((item) => item.includes(CONFLUENCE_KEY));

			if (canInviteToConfluence) {
				setIsCurrentlyInviteEligible(true);
			} else {
				void setOnboardingState({
					key: LIVE_PAGE_NUDGE.LIVE_PAGE_INVITE_INELIGIBLE,
					value: 'true',
				});
			}
		};
		void getInvitePermissions();
	}, [
		isLoading,
		error,
		skip,
		cloudId,
		hasNoInvitePermissions,
		setOnboardingState,
		createAnalyticsEvent,
		isCurrentUserContributor,
	]);

	const isEligibleForLivePageNudge =
		!isLoading &&
		!skip &&
		!error &&
		isCurrentUserContributor &&
		!hasNoInvitePermissions &&
		isCurrentlyInviteEligible;

	return {
		isLoading,
		isEligibleForLivePageNudge,
		isShowingLivePageNudge,
		showSpotlight,
		markFirstLivePageAsSeen,
		livePageNudgeHasSeenFirstLivePage,
	};
};
