import FeatureGates from '@atlaskit/feature-gate-js-client';

export const useIsFolderContentViewEnabled = (): {
	isFolderContentViewEnabled: boolean;
} => {
	const isFolderContentViewEnabled =
		FeatureGates.getExperimentValue<'test' | 'control'>(
			'confluence_cloud_folders_content_view_experiment',
			'cohort',
			'control',
		) === 'test';
	return { isFolderContentViewEnabled };
};
