import { useEffect, useState } from 'react';

import { isPersonalSpace as checkIsPersonalSpace } from '@confluence/space-utils';
import {
	useGetOnboardingState,
	useOnboardingState,
	deserializeState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { ONBOARDING_EXPERIENCES } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { useIsLivePage } from '@confluence/live-pages-utils/entry-points/useIsLivePage';
import { useLivePageOnboardingNudgeEligible } from '@confluence/onboarding-editor/entry-points/hooks/useLivePageOnboardingNudgeEligible';

import { useSampleContentExperiment } from './useSampleContentExperiment';

interface UseSpotlightProps {
	spaceKey?: string;
	isTargetReady: boolean;
	contentId?: string;
}

export const useSpotlight = ({ spaceKey, isTargetReady, contentId }: UseSpotlightProps) => {
	const { setOnboardingState } = useOnboardingState();
	const { isUserInTreatment } = useSampleContentExperiment();
	const { data: onboardingStateData } = useGetOnboardingState([
		ONBOARDING_EXPERIENCES.IS_SAMPLE_CONTENT_SPOTLIGHT_VISIBLE,
	]);
	const { isSampleContentSpotlightVisible: shouldUserSeeSpotlight } =
		deserializeState(onboardingStateData);
	const [areSpotlightConditionsMet, setAreSpotlightConditionsMet] = useState(false);
	const [hasSpotlightShown, setHasSpotlightShown] = useState(false);
	const isPersonalSpace = checkIsPersonalSpace(spaceKey);
	const isLivePage = useIsLivePage();
	const { isLoading, isEligibleForLivePageNudge } = useLivePageOnboardingNudgeEligible({
		contentId,
	});
	const hideForLivePageNudge = !isLoading && isLivePage && isEligibleForLivePageNudge;

	const setHasSpotlightShownExternal = () => {
		if (!hasSpotlightShown) {
			setHasSpotlightShown(true);
			setOnboardingState({
				key: ONBOARDING_EXPERIENCES.IS_SAMPLE_CONTENT_SPOTLIGHT_VISIBLE,
				value: 'false',
			});
		}
	};

	useEffect(() => {
		if (
			isUserInTreatment &&
			shouldUserSeeSpotlight &&
			!hasSpotlightShown &&
			isTargetReady &&
			isPersonalSpace &&
			!hideForLivePageNudge
		) {
			setTimeout(() => {
				setAreSpotlightConditionsMet(true);
			}, 2000);
		}
	}, [
		isTargetReady,
		setAreSpotlightConditionsMet,
		isPersonalSpace,
		setOnboardingState,
		isUserInTreatment,
		shouldUserSeeSpotlight,
		hasSpotlightShown,
		hideForLivePageNudge,
	]);
	const dismiss = () => {
		setAreSpotlightConditionsMet(false);
	};
	return {
		areSpotlightConditionsMet,
		dismiss,
		setHasSpotlightShown: setHasSpotlightShownExternal,
	};
};
